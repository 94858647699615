import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../services/api-service.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class VendorOffersService {

  refreshList = new BehaviorSubject<boolean>(false);
  constructor(private _apiService: ApiServiceService) { }



  getOffers(model: any): any {
    return this._apiService.get(`${environment.apiUrl}/offers`, model)
      .pipe(catchError(this.handleError));
  }

  getOfferTypes(): any {
    return this._apiService.get(`${environment.apiUrl}/offers/types`)
      .pipe(catchError(this.handleError));
  }

  getBranchesOfVendor(vendorId: any): any {
    return this._apiService.get(`${environment.apiUrl}/vendors/${vendorId}/branches`)
      .pipe(catchError(this.handleError));
  }

  CompaniesSearch(model: any) {
    return this._apiService.get(`${environment.apiUrl}/companies-list-with-search`, model)
      .pipe(catchError(this.handleError));
  }

  ExportOffers(model: any) {
    return this._apiService.getDownload(`${environment.apiUrl}/offers/export/excel`, model)
      .pipe(catchError(this.handleError));
  }

  PrintPDF_Offers(model: any) {
    return this._apiService.getDownload(`${environment.apiUrl}/offers/export/pdf`, model)
      .pipe(catchError(this.handleError));
  }

  // Offer Details Page
  GetOfferDetails(id: string) {
    return this._apiService.get(`${environment.apiUrl}/offers/details?offer_id=${id}`)
      .pipe(catchError(this.handleError));
  }

  GetEmployeesRedeemedOffer(model: any) {
    return this._apiService.get(`${environment.apiUrl}/offers/offer-not-applied-users`, model)
      .pipe(catchError(this.handleError));
  }

  GetEmployeesClaimedOffer(model: any) {
    return this._apiService.get(`${environment.apiUrl}/offers/offer-applied-users`, model)
      .pipe(catchError(this.handleError));
  }

  GetOfferBranches(model: any) {
    return this._apiService.get(`${environment.apiUrl}/offers/offer-branches`, model)
      .pipe(catchError(this.handleError));
  }

  GetOfferCompanies(model: any) {
    return this._apiService.get(`${environment.apiUrl}/offers/offer-companies`, model)
      .pipe(catchError(this.handleError));
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName);
  }

  companiesWithDepartmentForOffer(filter: any) {
    return this._apiService.get(`${environment.apiUrl}/vendor-admin/companies-with-department-for-offer/`, filter)
      .pipe(catchError(this.handleError));
  }

  getBranchesForVendor(vendorId?: any, offerId?: any) {
    return this._apiService.get(`${environment.apiUrl}/vendor-admin/branches-for-offer/${offerId ? `?offer_id=${offerId}&`: `?`}vendor_id=${vendorId}`)
      .pipe(catchError(this.handleError));
  }

  // Offer // ADD Offer
  saveOffer(data: any, id: any = null) {
    if (id) {
      return this._apiService.post(`${environment.apiUrl}/vendor-admin/offers/${id}`, data)
        .pipe(catchError(this.handleError));
    } else {
      return this._apiService.post(`${environment.apiUrl}/vendor-admin/offers`, data)
        .pipe(catchError(this.handleError));
    }
  }

  deleteOffer(id: any) {
    return this._apiService.remove(`${environment.apiUrl}/vendor-admin/offers/${id}`)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

}
